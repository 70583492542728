export var getPath = function getPath(req) {
  if (req.path) return req.path;
  if (req.raw && req.raw.path) return req.raw.path;
  if (req.url) return req.url;
  console.log('no possibility found to get path');
};
export var getUrl = function getUrl(req) {
  if (req.url && req.url.href) return req.url.href;
  if (req.url) return req.url;
  if (req.raw && req.raw.url) return req.raw.url;
  console.log('no possibility found to get url');
};
export var setUrl = function setUrl(req, url) {
  if (req.url) {
    req.url = url;
    return;
  }
  console.log('no possibility found to get url');
};
export var getOriginalUrl = function getOriginalUrl(req) {
  if (req.originalUrl) return req.originalUrl;
  if (req.raw && req.raw.originalUrl) return req.raw.originalUrl;
  return getUrl(req);
};
export var getQuery = function getQuery(req) {
  if (req.query && typeof req.query.entries === 'function' && typeof Object.fromEntries === 'function' && typeof req.query[Symbol.iterator] === 'function') {
    return Object.fromEntries(req.query);
  }
  if (req.query) return req.query;
  if (req.searchParams) return req.searchParams;
  if (req.raw && req.raw.query) return req.raw.query;
  if (req.ctx && req.ctx.queryParams) return req.ctx.queryParams;
  if (req.url && req.url.searchParams) return req.url.searchParams;
  var url = req.url || req.raw && req.raw.url;
  if (url && url.indexOf('?') < 0) return {};
  console.log('no possibility found to get query');
  return {};
};
export var getParams = function getParams(req) {
  if (req.params) return req.params;
  if (req.raw && req.raw.params) return req.raw.params;
  if (req.ctx && req.ctx.params) return req.ctx.params;
  console.log('no possibility found to get params');
  return {};
};
export var getHeaders = function getHeaders(req) {
  if (req.headers) return req.headers;
  console.log('no possibility found to get headers');
};
export var getCookies = function getCookies(req) {
  if (req.cookies) return req.cookies;
  if (getHeaders(req)) {
    var list = {};
    var rc = getHeaders(req).cookie;
    rc && rc.split(';').forEach(function (cookie) {
      var parts = cookie.split('=');
      list[parts.shift().trim()] = decodeURI(encodeURI(parts.join('=')));
    });
    return list;
  }
  console.log('no possibility found to get cookies');
};
export var getBody = function getBody(req) {
  if (req.ctx && typeof req.ctx.body === 'function') {
    return req.ctx.body.bind(req.ctx);
  }
  if (req.ctx && req.ctx.body) return req.ctx.body;
  if (req.json) return req.json;
  if (req.body) return req.body;
  if (req.payload) return req.payload;
  if (req.request && req.request.body) return req.request.body;
  console.log('no possibility found to get body');
  return {};
};
export var getHeader = function getHeader(res, name) {
  if (res.getHeader) return res.getHeader(name);
  if (res.headers) return res.headers[name];
  if (getHeaders(res) && getHeaders(res)[name]) return getHeaders(res)[name];
  console.log('no possibility found to get header');
  return undefined;
};
export var setHeader = function setHeader(res, name, value) {
  if (res._headerSent || res.headersSent) return;
  if (typeof res.setHeader === 'function') return res.setHeader(name, value);
  if (typeof res.header === 'function') return res.header(name, value);
  if (res.responseHeaders && typeof res.responseHeaders.set === 'function') {
    return res.responseHeaders.set(name, value);
  }
  if (res.headers && typeof res.headers.set === 'function') {
    return res.headers.set(name, value);
  }
  if (typeof res.set === 'function') {
    return res.set(name, value);
  }
  console.log('no possibility found to set header');
};
export var setContentType = function setContentType(res, type) {
  if (typeof res.contentType === 'function') return res.contentType(type);
  if (typeof res.type === 'function') return res.type(type);
  setHeader(res, 'Content-Type', type);
};
export var setStatus = function setStatus(res, code) {
  if (typeof res.status === 'function') return res.status(code);
  if (res.status) return res.status = code;
  console.log('no possibility found to set status');
};
export var send = function send(res, body) {
  if (typeof res.send === 'function') return res.send(body);
  if (res.request && res.response && res.app) res.body = body;
  return body;
};
export var getSession = function getSession(req) {
  if (req.session) return req.session;
  if (req.raw && req.raw.session) return req.raw.session;
  console.log('no possibility found to get session');
};
export var extendOptionsWithDefaults = function extendOptionsWithDefaults() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  options.getPath = options.getPath || getPath;
  options.getOriginalUrl = options.getOriginalUrl || getOriginalUrl;
  options.getUrl = options.getUrl || getUrl;
  options.setUrl = options.setUrl || setUrl;
  options.getParams = options.getParams || getParams;
  options.getSession = options.getSession || getSession;
  options.getQuery = options.getQuery || getQuery;
  options.getCookies = options.getCookies || getCookies;
  options.getBody = options.getBody || getBody;
  options.getHeaders = options.getHeaders || getHeaders;
  options.getHeader = options.getHeader || getHeader;
  options.setHeader = options.setHeader || setHeader;
  options.setContentType = options.setContentType || setContentType;
  options.setStatus = options.setStatus || setStatus;
  options.send = options.send || send;
  return options;
};