function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as utils from './utils.js';
import cookieLookup from './languageLookups/cookie.js';
import querystringLookup from './languageLookups/querystring.js';
import pathLookup from './languageLookups/path.js';
import headerLookup from './languageLookups/header.js';
import sessionLookup from './languageLookups/session.js';
import { extendOptionsWithDefaults } from './httpFunctions.js';
function getDefaults() {
  return extendOptionsWithDefaults({
    order: ['querystring', 'cookie', 'header'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupSession: 'lng',
    lookupFromPathIndex: 0,
    caches: false,
    cookieSameSite: 'strict',
    ignoreCase: true,
    convertDetectedLanguage: function convertDetectedLanguage(l) {
      return l;
    }
  });
}
var LanguageDetector = function () {
  function LanguageDetector(services) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var allOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    _classCallCheck(this, LanguageDetector);
    this.type = 'languageDetector';
    this.detectors = {};
    this.init(services, options, allOptions);
  }
  _createClass(LanguageDetector, [{
    key: "init",
    value: function init(services) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var allOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.services = services;
      this.options = utils.defaults(options, this.options || {}, getDefaults());
      this.allOptions = allOptions;
      if (typeof this.options.convertDetectedLanguage === 'string' && this.options.convertDetectedLanguage.indexOf('15897') > -1) {
        this.options.convertDetectedLanguage = function (l) {
          return l.replace('-', '_');
        };
      }
      this.addDetector(cookieLookup);
      this.addDetector(querystringLookup);
      this.addDetector(pathLookup);
      this.addDetector(headerLookup);
      this.addDetector(sessionLookup);
    }
  }, {
    key: "addDetector",
    value: function addDetector(detector) {
      this.detectors[detector.name] = detector;
    }
  }, {
    key: "detect",
    value: function detect(req, res, detectionOrder) {
      var _this = this;
      if (arguments.length < 2) return;
      if (!detectionOrder) detectionOrder = this.options.order;
      var found;
      detectionOrder.forEach(function (detectorName) {
        if (found || !_this.detectors[detectorName]) return;
        var detections = _this.detectors[detectorName].lookup(req, res, _this.options);
        if (!detections) return;
        if (!Array.isArray(detections)) detections = [detections];
        detections = detections.filter(function (d) {
          return d !== undefined && d !== null;
        });
        detections = detections.map(function (d) {
          return _this.options.convertDetectedLanguage(d);
        });
        if (_this.services.languageUtils.getBestMatchFromCodes) {
          found = _this.services.languageUtils.getBestMatchFromCodes(detections);
          if (found) {
            if (_this.options.ignoreCase) {
              if (detections.map(function (d) {
                return d.toLowerCase();
              }).indexOf(found.toLowerCase()) < 0) found = undefined;
            } else {
              if (detections.indexOf(found) < 0) found = undefined;
            }
          }
          if (found) req.i18nextLookupName = detectorName;
        } else {
          found = detections.length > 0 ? detections[0] : null;
        }
      });
      if (!found) {
        var fallbacks = this.allOptions.fallbackLng;
        if (typeof fallbacks === 'function') fallbacks = fallbacks();
        if (typeof fallbacks === 'string') fallbacks = [fallbacks];
        if (!fallbacks) fallbacks = [];
        if (Object.prototype.toString.apply(fallbacks) === '[object Array]') {
          found = fallbacks[0];
        } else {
          found = fallbacks[0] || fallbacks.default && fallbacks.default[0];
        }
      }
      ;
      return found;
    }
  }, {
    key: "cacheUserLanguage",
    value: function cacheUserLanguage(req, res, lng, caches) {
      var _this2 = this;
      if (arguments.length < 3) return;
      if (!caches) caches = this.options.caches;
      if (!caches) return;
      caches.forEach(function (cacheName) {
        if (_this2.detectors[cacheName] && _this2.detectors[cacheName].cacheUserLanguage && res.cachedUserLanguage !== lng) {
          _this2.detectors[cacheName].cacheUserLanguage(req, res, lng, _this2.options);
          res.cachedUserLanguage = lng;
        }
      });
    }
  }]);
  return LanguageDetector;
}();
LanguageDetector.type = 'languageDetector';
export default LanguageDetector;